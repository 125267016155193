import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  BannerLinks,
  BannerSmall,
  Image,
  ProjectPagination,
  QuickFacts,
  SplitView
} from '../../components'

import HoverButton from '../../components/HoverButton'
import boxes from '../../components/BannerLinks/DefaultBox'
import { generateBreadcrumbsLinks } from '../../utils/index'

import BannerImage from '../../assets/banner.alpinamed.jpg'
import changeOverview from '../../assets/alpinamed-referenz-square.png'
import alupress from '../../assets/banner.alupress.png'
import omk from '../../assets/header.arbeiterkammer.png'

// const slides = [
//   { eachSlide: home, alt: 'IKB Website Startseite' },
//   { eachSlide: contact, alt: 'IKB Website Kontakt' },
// ];

const Alpinamed = () => {
  const links = generateBreadcrumbsLinks('/projekte/alpinamed', 'Alpinamed')
  return (
    <Layout margin={false} color="white">
      <Helmet
        title="Alpinamed"
        meta={[
          {
            name: 'description',
            content:
              'Die Alpinamed AG hat sich für einen Wechsel zur holzweg GmbH entschieden!'
          },
          {
            name: 'keywords',
            content:
              'Alpinamed holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, Alpinamed'
          },
          {
            property: 'og:description',
            content:
              'Die Alpinamed AG hat sich für einen Wechsel zur holzweg GmbH entschieden!'
          },
          {
            property: 'og:image',
            content: 'https://www.holzweg.com/static/banner.alpinamed-d03d7f1aa289777a999b1fa069b42578.jpg'
          }
        ]}
      />
      <BannerSmall image={BannerImage} overlay={true} title="Mit Naturprodukten gesünder Leben" />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <>
            <div>
              Alpinamed <br /> Website Relaunch
            </div>
            <HoverButton buttonStyle="" dataText="Alpinamed.at" dataTitle="Explore!" link={"https://www.alpinamed.at"} />
          </>
        }
        text={
          <span>
            <p>
              Entdecken Sie eine vielfältige Welt von pflanzlichen Extrakten, Nahrungsergänzungsmitteln und Kosmetika. Nachdem die Alpinamed AG die Expertise von holzweg in Anspruch genommen hat, präsentiert sich ihre Webseite
              {''} <a href="https://www.alpinamed.at"> alpinamed.at </a> {''}
              seit September 2022 in einem zeitgemäßen Design. Durch den Einsatz moderner Technologien wurden maßgeschneiderte Content-Elemente geschaffen, die auf die Bedürfnisse zugeschnitten sind. Mit Hilfe des CMS Typo3 wird der Inhalt optimal platziert, während die Open-Source-Software H5P die Türen für kreativen interaktiven Content auf Desktops und Mobilgeräten geöffnet hat.
            </p>
          </span>
        }
      />
      <QuickFacts
        facts={[
          { count: 73, suffix: '%', text: 'mobile User' },
          { count: 100000, suffix: '', text: 'jährliche User' },
          { count: 100, suffix: '%', text: 'modernes User Interface' }
        ]}
        color="red"
      />
            <SplitView
        displayImageLeft={false}
        title="Design und Interaktion im Einklang"
        text={
          <>
            <br />
            <p>
            Unser Webentwicklungsprojekt vereint modernes Design mit interaktivem Content. Durch sorgfältige Gestaltung schaffen wir eine ästhetisch ansprechende Plattform, die sich nahtlos in den heutigen digitalen Raum einfügt. Gepaart mit interaktivem Content bieten wir den Besuchern ein fesselndes Erlebnis, das über reine Informationen hinausgeht. Durch geschickte Technologien verwandeln wir statische Inhalte in interaktive Elemente, die das Engagement steigern und die Nutzer aktiv einbeziehen. So schaffen wir eine dynamische und beeindruckende Online-Präsenz, die die Erwartungen übertrifft.
            </p>
            <br />
            {/* <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.
            </p> */}
          </>
        }
        linkText="Mehr Eindrücke entdecken"
        link='#slider'
        backgroundImage={changeOverview}
      />
      {/* <Image image={changeOverview} /> */}
      {/* <HeaderSlider slides={slides} /> */}
      {/* <TextBox
        title=''
        buttons={[
          { link: 'https://www.alpinamed.at', text: 'Zur Website' },
        ]}
      /> */}
      <TextBox title="Weitere Projekte" titleMods={['center']} />
      <ProjectPagination
        titleLeft="Online mit Köpfchen"
        imageLeft={omk}
        leftLink="/projekte/arbeiterkammer"
        titleRight="Alupress Homepage"
        imageRight={alupress}
        rightLink="/projekte/alupress"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Alpinamed
